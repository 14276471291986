"use client";
import { useState } from "react";
import Link from "next/link";
import Vector from "@/components/icons/Vector";
import { usePathname } from "next/navigation";
import logo from "@/../public/smalllogo.svg"
import Image from "next/image";


const NavbarMobile = () => {
  const [navbar, setNavbar] = useState(false);
  const pathName = usePathname();

  const toggleNavbar = () => {
    setNavbar(!navbar);
  };

  return (
    <div className="bg-white z-20 lg:hidden block  h-[58px]">
      <nav className="w-[100%] fixed  lg:hidden block md:block  bg-white ">
        <div className="justify-between  z-20 bg-white ps-4 mx-auto">
          <div>
            <div className="flex items-center justify-between py-2 mt-1">
              {/* LOGO */}
              <Link href="/" className="ms-5">
                <Image src={logo} alt="logo" className="w-[64px] h-[58px]" width={64} height={58}/>
              </Link>
              {/* HAMBURGER BUTTON FOR MOBILE */}
              <div className="flex pe-3 justify-end items-center">
                <button
                  className="py-2  text-gray-700 rounded-md outline-none"
                  onClick={toggleNavbar}
                >
                  {navbar ? (
                   <Vector/>
                  ) : (
                    <Vector/>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`flex-1 bg-white z-50 h-screen justify-self-center pb-3 mt-4 block   ${
                navbar ? "p-1 block z-20 bg-white" : "hidden"
              }`}
            >
              <ul className='menu menu-vertical pt-5 bg-white gap-10 mx-auto'>
           
              <Link href='/'>
              <button onClick={toggleNavbar}  className={pathName === '/' ? 'active-route ' : 'default-route'}>
               Home
              </button>
              </Link>
              <Link href="https://demo.hastyhead.com/" target="_blank">
              <button onClick={toggleNavbar}  className={pathName === '/portfolio' ? 'active-route ' : 'default-route'}>
               Portfolio
              </button>
              </Link>
          
              <Link href='/services&pricing'>
              <button onClick={toggleNavbar}  className={pathName === '/services&pricing'? 'active-route ' : 'default-route'}>
               Services & Pricing
              </button>
              </Link>
              <Link href='/about_us'>
             
             <button onClick={toggleNavbar}  className={pathName === '/about_us' ? 'active-route ' : 'default-route'}>
              About Us
             </button>
             </Link>
             
              <Link href='/blog'>
              <button onClick={toggleNavbar}  className={pathName === '/blog'? 'active-route' : 'default-route'}>
               Blog
              </button>
              </Link>
             
              <Link href='https://hastyhead.notion.site/Work-at-Hasty-Head-Ltd-7beb7c08ea6f490d8e9b083c3e029376'   target="_blank" >
              <button onClick={toggleNavbar}  className={pathName === '/career'? 'active-route' : 'default-route'}>
               Career
              </button>
              </Link>


             
              <Link href='/contact_us'>
              <button onClick={toggleNavbar}  className={pathName === '/contact_us'? 'active-route' : 'default-route'}>
               Contact
              </button>
              </Link>
             
            </ul>
              
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavbarMobile;
