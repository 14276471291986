import React from 'react'

export default function Vector() {
  return (
    <div>
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.62089 0.500944H26.0613C28.1401 0.421573 28.1401 3.58132 26.0613 3.50195H6.69838C4.65739 3.58132 4.57802 0.500944 6.619 0.500944H6.62089ZM26.0613 12.5012C28.0607 12.5012 28.0607 15.5003 26.0613 15.5003H15.1004C13.0594 15.5003 13.0594 12.5012 15.1004 12.5012H26.0613ZM26.0613 24.5014C28.0607 24.5014 28.0607 27.5005 26.0613 27.5005H1.49953C-0.499878 27.5005 -0.499878 24.5014 1.49953 24.5014H26.0613Z" fill="#605C66"/>
</svg>

    </div>
  )
}
