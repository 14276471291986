"use client";

import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import NavbarMobile from './NavbarMobile';
import Redirect from '../icons/Redirect';
import Image from 'next/image';
import logo from "@/../public/logo.png"


export default function Navbar() {
  const pathName = usePathname();
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='mx-auto'>

<div
        className={`bg-white lg:hidden  z-50 block fixed w-full ${
          scroll ? " bg-white w-full  lg:hidden block fixed top-0  z-50" : ""
        }`}
      >
        <NavbarMobile />
      </div>
      {/* web navbar */}
      <div className='lg:block hidden'>
      <div className={`fixed w-full z-20 ${scroll ? 'bg-white pb-2 ' : ''}`}>
        <div className='flex justify-between items-center max-w-[1170px] mx-auto'>
          <div className=''>
            {/* logo */}
            <Link href="/">
              <Image src={logo} alt='logo' className='w-[85px] h-[76px]' width={85} height={76} />
            </Link>
          </div>
          <div className='flex justify-end pt-3 items-center gap-6 '>
          <Link href='/'>
              <button className={pathName === '/' ? 'active-route ' : ' default-route'}>
               Home
              </button>
              </Link>
              <Link href="https://demo.hastyhead.com/" target="_blank">
              <button className={pathName === '/portfolio' ? 'active-route flex justify-start items-center ps-1 gap-[6px]  ' : ' default-route flex justify-start items-center gap-[6px] ps-1'}>
               Portfolio 
               <span><Redirect/></span>
              </button>
              </Link>
         
              <Link href='/services&pricing'>
              <button className={pathName === '/services&pricing'? 'active-route' : 'default-route'}>
               Services & Pricing
              </button>
              </Link>
              <Link href='/about_us'>
             
             <button className={pathName === '/about_us' ? 'active-route  ' : 'default-route'}>
              About Us
             </button>
             </Link>
              <Link href='/blog'>
              <button className={pathName === '/blog'? 'active-route ' : 'default-route'}>
               Blog
              </button>
              </Link>
             
              <Link href='https://hastyhead.notion.site/Work-at-Hasty-Head-Ltd-7beb7c08ea6f490d8e9b083c3e029376'   target="_blank" >
              <button className={pathName === '/career'? 'active-route flex justify-start items-center gap-[6px]  ' : ' default-route flex justify-start items-center gap-[6px]'}>
               Career
               <span><Redirect/></span>
              </button>
              </Link>

              <Link href='/contact_us'>
              <button className={pathName === '/contact_us'? ' active-route' : 'default-route'}>
               Contact
              </button>
              </Link>

           
            </div>

           
        </div>
      </div>
      </div>

     
    </div>
  );
}
